import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  Route,
  Switch,
  withRouter,
  Link,
} from "react-router-dom";
import "./blog_home.css";
import $ from "jquery";
import marked from "markedjs"


const BlogLandingPage = (props) => {
  let rows = [];
  for(let idx in props.blog_metadata){
    let entry = props.blog_metadata[idx]
    rows.push(<Link key={idx} to={"/blog/" + entry.display_url}><BlogPreview blog_entry={entry} /></Link>);
  }

  return  <div className="blog-base">
            <div className="buffer" />
            <div className="blog-center" >
                <div className="title" >
                  <h1 className="no-margin">In The Wings:</h1>
                  <h2>Why we do, what we do</h2>
                </div>
                {rows}
            </div>
            <div className="buffer" />
          </div>
};

const BlogPreview = (props) => {
  return  <div className="blogPreviewEntry">
              <h2>{props.blog_entry.post_name}</h2>
              <h4>{"Author: " + props.blog_entry.post_author}</h4>
              <p>{props.blog_entry.intro}</p>
          </div>
}


class BlogPost extends React.Component{
  constructor(props){
    super(props)



    let postID = this.props.location.pathname.split('/')[2]

    let fullURL = ""
    let postName = ""
    for(let idx in props.blog_metadata){
      let entry = props.blog_metadata[idx]
      if (entry.display_url === postID){
        fullURL = entry.post_url;
        postName = entry.post_name
      }
    }

    $.get(fullURL, function(data) {
      this.setState({md_as_data: data});
    }.bind(this), 'text');

    this.state = {
      md_as_data: null,
      post_name: postName
    }
  }   
  
  render() {
    let screen = <div></div>
    if(this.state.md_as_data !== null){
      let asString = marked(this.state.md_as_data);
      screen = <div className="blog-base">
                <div className="buffer" />
                <div className="blog-post" >
                  <h2 >{this.state.post_name}</h2>
                 {ReactHtmlParser(asString)}
                 </div>
                 <div className="buffer" />
               </div>;
    }

    return screen
  
  }
};

const BlogPostWithRouter = withRouter(BlogPost)

class BlogHome extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      blog_metadata: null
    }
    $.getJSON("/blog_posts/_blog_metadata.json", function(data){
      this.setState({blog_metadata: data})
    }.bind(this));
  }

  render() {
    if(this.state.blog_metadata === null){
      return <div className="blog-placeholder"></div>
    }

    return (
      <Switch>
        <Route exact path="/blog" render ={() => <BlogLandingPage blog_metadata={this.state.blog_metadata}/>} />
        <Route exact path="/blog/:postID" render={() => <BlogPostWithRouter blog_metadata={this.state.blog_metadata}/>} />
      </Switch>
 
    );
  }
}

export default withRouter(BlogHome);
