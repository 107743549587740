import React from 'react';
import {Howl} from 'howler';
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "../../../reducer";


class DialogueMoment extends React.Component {
  constructor(props) {
    super(props);

    this.playDialogue = this.playDialogue.bind(this);
    this.dialogueReady = this.dialogueReady.bind(this);
    this.onComplete = this.onComplete.bind(this);

    let howlHandle = this.loadDialogue();
    
    this.state = {
      howlHandle: howlHandle,
      audioIsPlaying: null,
    }
  }

  reset() {
    this.setState({audioIsPlaying: false});
  }

  loadDialogue() {
    let howlHandle = new Howl({
      src:[this.props.jsonObj.filePath],
      autoplay: false,
      onload: this.dialogueReady,
      onend: this.onComplete
    })
    return howlHandle
  }

  dialogueReady() {
      this.props.socket.emit('audioLoaded', {
        momentName: this.props.currentMoment.momentName,
      });
  }
  onComplete() {
    this.props.setmomentComplete()
  }

  playDialogue() {
    if (this.state.howlHandle !== null && this.state.audioIsPlaying === null) {
      let playHandle = this.state.howlHandle.play();
      this.setState({audioIsPlaying : playHandle});
    }
  }

  componentDidMount(){
    this.props.socket.on('startAudio', (msg) => {
      this.playDialogue();
    });

  }

  componentWillUnmount() {
    this.state.howlHandle.stop();
    this.state.howlHandle.unload();
    this.props.socket.off('startAudio');
  }

  render() {
    return <div>
      <div className="dialogue-instructions">
        <div>Make sure your volume is turned on to hear the performance.</div>
      </div>
    </div>;
  }
}

let DialogueMomentConnected = connect(mapStateToProps, mapDispatchToProps)(DialogueMoment);
export default DialogueMomentConnected;
