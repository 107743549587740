import React from 'react';


class RoleDescription extends React.Component {
  constructor(props) {
    super(props);
    this.onRoleSelected = this.onRoleSelected.bind(this);
  }

  onRoleSelected() {
    let onlyOneRole = (this.props.playInfo.allowUnlimitedRoles !== true)
    let oneRoleSelected = this.props.activeRoleNames.length > 0;

    let roleName = this.props.roleInfo.roleName
    if(!this.props.otherRolesSelected.includes(roleName) || (this.props.playInfo.allRolesMustBeFilled === false)){
      if (this.props.activeRoleNames.includes(roleName)){
        this.props.removeRole(roleName);
        this.props.socket.emit("removeRole", {"roleName": roleName});
      }else if(!onlyOneRole || !oneRoleSelected){
        this.props.selectRole(roleName);
        this.props.socket.emit("roleSelected", {"roleName": roleName});
      }
    }
  }

  render() {
    let className = "roleDescription"
    let roleName = this.props.roleInfo.roleName;
    let onlyOneRole = (this.props.playInfo.allowUnlimitedRoles !== true)
    let oneRoleSelected = this.props.activeRoleNames.length > 0;

    if(this.props.activeRoleNames.includes(roleName)){
      className = "roleDescriptionSelected"
    }else if((this.props.otherRolesSelected.includes(roleName) && this.props.playInfo.allRolesMustBeFilled !== false) || (onlyOneRole && oneRoleSelected)){
      className = "roleDescriptionOther"
    }
    return <div className={className} onClick={this.onRoleSelected} >
             <div className="role-description-width" key={'roleDescription' + this.props.roleInfo.roleName} >
                <p>{this.props.roleInfo.roleName}</p>
              </div>
           </div>;
  }
}

export default RoleDescription;
