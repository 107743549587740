import {Howl} from 'howler';
import React from 'react';
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "../../../reducer";



class AudioControlMoment extends React.Component {
  constructor(props) {
    super(props);

    this.playDialogue = this.playDialogue.bind(this);
    this.dialogueReady = this.dialogueReady.bind(this);
    this.onComplete = this.onComplete.bind(this);
    let howlHandle = this.loadDialogue();
    
    this.state = {
      howlHandle: howlHandle,
      audioIsPlaying: null,
      playbackRate: 1
    }
  }

  reset() {
    this.setState({audioIsPlaying: null});
    this.loadDialogue()
  }

  loadDialogue() {
    let howlHandle = new Howl({
      src:[this.props.jsonObj.filePath],
      autoplay: false,
      onload: this.dialogueReady,
      onend: this.onComplete,
    })
    return howlHandle
  }

  dialogueReady() {
    if (this.correctActor) {
      this.props.socket.emit('audioLoaded', {
        momentName: this.props.currentMoment.momentName,
      });
    }
  }
  onComplete() {
    this.props.setmomentComplete()
    this.stopAudio()
  }

  playDialogue() {
    if (this.state.howlHandle !== null && this.state.audioIsPlaying === null) {
      let playHandle = this.state.howlHandle.play();
      this.state.howlHandle.rate(this.state.playbackRate, playHandle)
      this.setState({audioIsPlaying : playHandle});
    }
  }

  componentDidMount(){
    this.props.socket.on('startAudio', (msg) => {
      this.playDialogue();
    });
    this.props.socket.on('stopAudio', (msg) => {
      this.stopAudio();
    });
    this.props.socket.on('playbackRateAdjustment', (msg) => {
      if(this.state.howlHandle !== null && this.state.audioIsPlaying !== null && msg.newRate){
        this.state.howlHandle.rate(msg.newRate, this.state.audioIsPlaying)
      }
      this.setState({playbackRate: msg.newRate});
    });
  }

  componentWillUnmount() {
    this.stopAudio()
    this.props.socket.off('startAudio');
    this.props.socket.off('stopAudio');
    this.props.socket.off('playbackRateAdjustment');
  }

  stopAudio(){
    this.state.howlHandle.stop();
    this.reset()
  }

  onClickFunc(){
    if(!this.state.audioIsPlaying){
      this.props.socket.emit("startAudioServer")
    }else{
      this.props.socket.emit("stopAudioServer")
    }
  }
  handleChange(event){
    if(event.target.value){
      this.props.socket.emit('playbackRateAdjustment', {
        newRate: event.target.value,
      });
    }else{
      this.setState({playbackRate: event.target.value});
    }
  }

  render() {
    this.loadDialogue()

    return <div>
      <div className="dialogue-instructions">
        <div className="playbackRateAdjustWrapper"><p>Playback rate:</p><div className="playbackSpacer" /><input className="rateAdjustmentInput" type="number" step="0.1" value={this.state.playbackRate} onChange={(event) => {this.handleChange(event)}} /></div>
        <div><p>Make sure your volume is turned on to hear the performance.</p></div>
        { !this.state.audioIsPlaying &&
            <button className="button" type="button" onClick={()=> {this.onClickFunc()}}>Start Audio</button> }
        { this.state.audioIsPlaying &&
            <button className="button" type="button" onClick={()=> {this.onClickFunc()}}>Stop Audio</button> }
      </div>
    </div>;
  }
}


let AudioControlMomentConnected = connect(mapStateToProps, mapDispatchToProps)(AudioControlMoment);
export default AudioControlMomentConnected;
