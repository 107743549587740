import React, { Component } from "react";
import ReactGA from 'react-ga';
import PlayBinConnected from "./immersion_room/play_bin.js"
import LandingPage from "./landing_page/landing_page.js"
import BlogHome from "./blog/blog_home.js"
import {createBrowserHistory} from 'history';
import {
  Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import socketIOClient from "socket.io-client"
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "./reducer.js";
const GA_TRACKING_ID = 'UA-64791632-8';

const history = createBrowserHistory()

history.listen(location =>{
  ReactGA.pageview(location.pathname)
});


const Header = () => {
  return  <div>
            <div className="menu-bar">
              <Link to="/">
                <img className="menu-logo" alt="Logo" src="/images/brand_assets/text_logo_color.svg" />
              </Link>
              <div className="right links">
                  <Link to="/" className="menu-item">Home</Link>
                  <Link to="/immersion" className="menu-item">Immersion Room</Link>
                  <Link to="/blog" className="menu-item">In The Wings</Link>
              </div>
            </div>
          </div>
}

const Footer = () => {
  return <div className="footer">
    ©2020 inRep, a theater company. All rights reserved.
  </div>
}

class App extends Component {
  constructor(props){
    super(props);
    this.props.setSocket(socketIOClient(window.location.hostname))
    ReactGA.initialize(GA_TRACKING_ID)
  }

  componentDidMount() {
  }

  render(){
    return (
      <Router history={history}>
        <div className="app-base">
          <Header />
            <div className="break" />
            <Switch>
              <Route exact path="/">
                  <LandingPage />
              </Route>
              <Route path="/immersion">
                  <PlayBinConnected />
              </Route>
              <Route path="/blog" component={BlogHome} />
            </Switch>
            <div className="break" />
          <Footer />
        </div>
      </Router>
    );
  }
};        

let AppConnected = connect(mapStateToProps, mapDispatchToProps)(App);
export default AppConnected;