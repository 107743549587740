import React from 'react';
import {getBlankScreen} from '../helper_classes.js';
import {Howl} from 'howler';
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "../../../reducer";


class GeoLocationTriggerMoment extends React.Component {
  constructor(props) {
    super(props);
    this.soundTrigger = props.jsonObj.soundTrigger;
    this.autoAdvance = props.jsonObj.autoAdvance;

    this.triggerLatitude = props.jsonObj.triggerLatitude;
    this.triggerLongitude = props.jsonObj.triggerLongitude;
    this.triggerLatRange = props.jsonObj.triggerLatRange;
    this.triggerLonRange = props.jsonObj.triggerLonRange;

    this.soundLoaded = false;
    this.soundComplete = false;
    this.queue = null;
    this.audioFile = null
    this.currentlyPlayingAudio = null;
    this.correctActor = false;

    this.playSound = this.playSound.bind(this);
    this.soundReady = this.soundReady.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.loadSound();

    this.lastPositionUpdate = 0;
    this.latitude = 0;
    this.longitude = 0;

    this.lastTriggerCheck = 0;
  }

  loadSound() {
    this.audioFile = new Howl({
      src:[this.soundTrigger],
      autoplay: false,
      onload: this.dialogueReady,
      onend: this.onComplete
    })
  }

  soundReady() {
    this.soundLoaded = true;
  }

  onComplete() {
    this.soundComplete = true;
  }

  playSound() {
    if (this.currentlyPlayingAudio === null) {
      this.currentlyPlayingAudio = this.audioFile.play();
    }
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    }
  }

  checkLocation() {
    const currentTime = new Date().getTime() / 1000;

    if (currentTime - this.lastPositionUpdate > 3) {
      this.getLocation();
      this.lastPositionUpdate = currentTime;
    }
  }

  checkTriggerMet() {
    if (this.currentlyPlayingAudio != null) {
      return;
    }

    const currentTime = new Date().getTime() / 1000;

    if (currentTime - this.lastTriggerCheck > 3) {
      this.lastTriggerCheck = currentTime;
      const latDiff = Math.abs(this.latitude - this.triggerLatitude);
      const lonDiff = Math.abs(this.longitude - this.triggerLongitude);
      if (latDiff < this.triggerLatRange &&
          lonDiff < this.triggerLonRange) {
        this.playSound();
      }
    }
  }

  render() {
    const relevantMoment = getBlankScreen();
    if (!(this.props.activeRoleNames.includes(this.roleName))) {
      return relevantMoment;
    }
    this.correctActor = true;

    this.checkLocation();

    this.checkTriggerMet();

    return <div>
      <div className="fullRow">
                Walk around a bit. Maybe something will happen!
      </div>
    </div>;
  }
}

let GeoLocationTriggerMomentConnected = connect(mapStateToProps, mapDispatchToProps)(GeoLocationTriggerMoment);
export default GeoLocationTriggerMomentConnected;
