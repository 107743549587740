import React from 'react';
import {getBlankScreen} from '../helper_classes.js';
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "../../../reducer";

class PhilipsHuePlaybackMoment extends React.Component {
  constructor(props) {
    super(props);
    this.bridgeUser = props.bridgeUser;
    this.selectedLamps = props.selectedLamps;
    this.queueSet = props.jsonObj.queueSet;
    this.queueTracking = {
      currentIdx: 0,
      queueValues: null,
      timeQueueStarted: null,
      finalMessageSent: false,
      lightsLocked: new Set(),
    };
  }

  render() {
    const relevantMoment = getBlankScreen();
    return relevantMoment;
  }

  currentQueueIsFinished() {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - this.queueTracking.timeQueueStarted;
    const totalQueueTime = this.queueTracking.queueValues.timeToCompleteMS + this.queueTracking.queueValues.waitAfterCompletionMS;
    return totalQueueTime <= timeDifference;
  }

  checkForLightTransitions() {
    // No queues to run
    if (this.queueSet.length === 0 || this.queueTracking.currentIdx >= this.queueSet.length) {
      return;
    }
    // Reasons why we need to start the next queue
    if ((this.queueTracking.queueValues === null) ||
       (this.queueTracking.finalMessageSent && this.currentQueueIsFinished())) {
      this.startQueue();
    }

    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - this.queueTracking.timeQueueStarted;
    const totalQueueTime = this.queueTracking.queueValues.timeToCompleteMS;
    this.queueTracking.finalMessageSent = timeElapsed > totalQueueTime;
    const percentComplete = totalQueueTime > 0 ? Math.min((timeElapsed/totalQueueTime * 100), 100)/100 : 0;
    const messageToLights = this.constructMessage(percentComplete);
    this.setLights(messageToLights);
  }

  constructMessage(percentComplete) {
    const vals = this.queueTracking.queueValues;
    const on = vals.on;
    const brightness = Math.round(vals.initBright + (percentComplete * (vals.finalBright - vals.initBright)));
    const hue = Math.round(vals.initHue + (percentComplete * (vals.finalHue - vals.initHue)));
    const sat = Math.round(vals.initSat + (percentComplete * (vals.finalSat - vals.initSat)));
    return {on: on, bri: brightness, hue: hue, sat: sat};
  }

  startQueue() {
    if (this.queueTracking.queueValues !== null) {
      this.queueTracking.currentIdx = this.queueTracking.currentIdx + 1;
    }

    if (this.queueTracking.currentIdx >= this.queueSet.length) {
      return;
    }

    this.queueTracking.queueValues = this.queueSet[this.queueTracking.currentIdx];
    this.queueTracking.timeQueueStarted = new Date().getTime();
    this.queueTracking.finalMessageSent = false;
  }

  setLights(msg) {
    this.selectedLamps.forEach(function(lamp) {
      if (this.queueTracking.lightsLocked.has(lamp.id) === false) {
        this.queueTracking.lightsLocked.add(lamp.id);
        this.bridgeUser.setLightState(lamp.id, msg).then(function(data) {
          this.queueTracking.lightsLocked.delete(lamp.id);
        }.bind(this));
      }
    }.bind(this));
  }

};

let PhilipsHuePlaybackMomentConnected = connect(mapStateToProps, mapDispatchToProps)(PhilipsHuePlaybackMoment);
export default PhilipsHuePlaybackMomentConnected;
