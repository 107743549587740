import React from 'react';
import PlayCover from './play_cover.js';

class PlayCategory extends React.Component {
  constructor(props) {
    super(props);
    this.onPlaySelected = this.onPlaySelected.bind(this);
  }

  onPlaySelected(playUUID) {
    // Pass up to the next level's callback
    this.props.onPlaySelected(playUUID);
  }

  componentWillUnmount() {
  }

  render() {
    const category = this.props.category;
    const playList = this.props.playList;
    const covers = [];
    let arbitraryUUID = 0;
    
    let i = 0;
    playList.forEach((playInfo) => {
      arbitraryUUID = playInfo.playUUID;
      covers.push(<PlayCover key={playInfo.playUUID} onPlaySelected={this.onPlaySelected} playInfo={playInfo}/>);
      i++;
      if(i % 3 === 0 && playList.length !== i){
        covers.push(<div key={"break" + i} className="break"/>);
      }
    });
    if (covers.length === 0) {
      return (null);
    }
    return (
      <div className="playCategory" key={'playCategory' + arbitraryUUID} id={category} >
        <div className="playCategoryTitleWrapper" key={'playCategoryTitleWrapper' + arbitraryUUID}>
          <h1 className="playCategoryTitle" key={'playCategoryTitle' + arbitraryUUID}>{category}</h1><br/>
        </div>
        <div className="coverSet" >
          { covers }
        </div>
      </div>
    );
  }
}

export default PlayCategory;
