import React from 'react';
import {
  Link,
} from "react-router-dom";

class PlayCover extends React.Component {
  constructor(props) {
    super(props);
    this.onPlaySelected = this.onPlaySelected.bind(this);
  }

  onPlaySelected() {
    // Pass up to the next level's callback
    this.props.onPlaySelected(this.props.playInfo.playUUID);
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div className="playCover" key={'playCover' + this.props.playInfo.playUUID} onClick={(e) => this.onPlaySelected(e)}>
        <Link to={"immersion/play/" + this.props.playInfo.playName}><img alt="Play Cover" src={this.props.playInfo.coverArtLink}></img></Link>
      </div>
    );
  }
}


export default PlayCover;
