import React from 'react';
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "../../../reducer";


class ActorReading extends React.Component {
  constructor(props) {
    super(props);
    this.backButton = this.backButton.bind(this);
    this.forwardButton = this.forwardButton.bind(this);
  }
  backButton(){
    if(this.props.momentNumber >= 1){
      this.props.socket.emit("goToMoment", {"momentNumber": this.props.momentNumber - 1})
    }
  }
  forwardButton(){
    this.props.setmomentComplete()
  }

  render() {
    return <div className="actorReadingWrapper">
              <div className="readingBackButton" ><img alt="Back Button" src="/images/back_button.svg" onClick={this.backButton} /> </div>
              <div className="readingCenter">
                <h2>{this.props.jsonObj.roleName}</h2>
                <pre>{this.props.jsonObj.dialogue}</pre>
              </div>
              <div className="readingForwardButton"><img alt="Forward Button" src="/images/forward_button.svg" onClick={this.forwardButton}  /></div>
           </div>
  }
};

let ActorReadingConnected = connect(mapStateToProps, mapDispatchToProps)(ActorReading);
export default ActorReadingConnected;
