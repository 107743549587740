import React from 'react';
import { connect } from "react-redux"
import {getBlankScreen} from '../helper_classes.js';
import {mapDispatchToProps, mapStateToProps} from "../../../reducer";


class AlexaMoment extends React.Component {
  render() {
    const relevantMoment = getBlankScreen();
    return relevantMoment
  }
};

let AlexaMomentConnected = connect(mapStateToProps, mapDispatchToProps)(AlexaMoment);
export default AlexaMomentConnected;
