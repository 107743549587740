import React from 'react';
import {
  withRouter
} from "react-router-dom";


class PerformanceIDSetup extends React.Component {
  constructor(props) {
    super(props);

    let connection_value = ""

    if(this.props.performanceID){
      connection_value = this.props.performanceID
    }

    this.state = {connection_value: connection_value,
                  connection_status_msg: ""};
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.serveAsHost = this.serveAsHost.bind(this);
    this.serveAsGuest = this.serveAsGuest.bind(this);
  }

  setupSubscribtions(){
    this.props.socket.on('performanceCreated', function(msg) {
      if (msg.connectionStatus) {
        this.props.selectPerformance(msg.performanceID);
        this.props.setIsHost(true)
      }else{
        this.setState({connection_status_msg: "Searching for space " + this.state.connection_value})
      }
    }.bind(this));
    this.props.socket.on('performanceConnection', function(msg) {
      if (msg.connectionStatus) {
        this.props.selectPerformance(msg.performanceID);
        this.setState({connection_status_msg: "Connection Successful!"})
        this.props.setIsHost(false)
        for(let idx in msg.claimedRoles){
          this.props.otherActorSelectedRole(msg.claimedRoles[idx])
        }
      }else{
        this.setState({connection_status_msg: "Searching for space " + this.state.connection_value})
        this.props.resetRoles()
        this.props.selectPerformance(null);
        this.props.history.push({search: ""});

      }
    }.bind(this));
  }

  requestPerformanceID(){
    this.props.socket.emit('connectToPerformanceID', {
      performanceID: this.props.performanceID,
      playUUID: this.props.playInfo.playUUID,
      activeRoleNames: this.props.activeRoleNames,
    });
  }

  handleChange(event) {
    this.setState({connection_value: event.target.value});
        // Require form submission to connect with a performance
        this.props.socket.emit('connectToPerformanceID', {
          performanceID: event.target.value,
          activeRoleNames: this.props.activeRoleNames,
          playUUID: this.props.playInfo.playUUID,
          onlyConnectToExisting: true
        });
  }
  onSubmit(event){
    event.preventDefault();
  }

  componentDidMount(){
    this.setupSubscribtions()
  }

  componentWillUnmount() {
    window.removeEventListener("onChange", this.handleChange);
    window.removeEventListener("onChange", this.handleChange);
    this.props.socket.off('performanceCreated');
    this.props.socket.off('performanceConnection');
  }

  serveAsHost(){
    this.props.setIsHost(true);
    if(!this.props.performanceID){
      this.requestPerformanceID()
    }
  }

  serveAsGuest(){
    this.props.setIsHost(false)
    this.props.selectPerformance(null);
    this.props.resetRoles();
    this.props.history.push({search: ""});
  }
  componentDidUpdate(){
    if(!this.props.isHost &&
      this.props.performanceID !== null &&
      this.props.performanceID !== this.state.connection_value){
        this.setState({connection_value: this.props.performanceID})
      }
  }

  render() {
    let display = <div></div>;

    let hostClassName="button";
    let guestClassName="button";
    if(this.props.isHost){
      hostClassName = "roleDescriptionSelected"
    }else if(this.props.isHost !== null){
      guestClassName = "roleDescriptionSelected"
    }

    display = <div id="performanceSpaceSetup">
      <div id ="hostTitleBlock">
        {this.props.isHost === null && <h1>Host or Join a Performance?</h1>}
        {this.props.isHost && <h1>You are the host</h1>}
        {this.props.isHost === false && <h1>You are joining a performance</h1>}
        <button className={hostClassName} type="button" onClick={this.serveAsHost}>Host</button>
        <button className={guestClassName} type="button" onClick={this.serveAsGuest}>Join</button>
        <p>(Every performance needs one performer to host - all others should join their space)</p>
      </div>

      {this.props.isHost &&
        <div>
            <p>A performance space is waiting for you. Have all other actors connect to the following space on their device:</p>
            <h2>{this.props.performanceID}</h2>
        </div>
          }
      {this.props.isHost === false &&
        <div>
          <p>Ask the host fo the performance space ID and enter it below.</p>
          <form onSubmit={this.onSubmit}>
            <label>Enter Performance Space: 
              <input type="text" value={this.state.connection_value} onChange={this.handleChange} />
              {this.state.connection_status_msg}
            </label>
          </form>
        </div>}
    </div>
    return (
      display
    );
  }
};


export default withRouter(PerformanceIDSetup);
