import React from 'react';
import RoleDescription from './role_description.js';
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "../reducer";

let RoleDescriptionConnected = connect(mapStateToProps, mapDispatchToProps)(RoleDescription);

class RoleList extends React.Component {
  render() {
    if (this.props.playInfo === null || this.props.playInfo === undefined) {
      return (null);
    }
    const roleInfoList = [];
    let i = 0;
    let arbitraryName = '';
    let alphabatized = this.props.playInfo.roles;
    alphabatized.sort(function(a,b){return a.roleName.localeCompare(b.roleName)})
    alphabatized.forEach((roleInfo) => {
      i++;
      arbitraryName = roleInfo.roleName;
      if (arbitraryName !== 'alexa_device') {
        roleInfoList.push(<RoleDescriptionConnected key={i} roleInfo={roleInfo} />);
      }
    });
    return (
      <div>
        <div id="roleSelectionHeader">
          <h1>Select a role</h1>
          <p>Available roles are listed in blue. Roles other actors have selected are in grey.</p>
        </div>
        <div className="roleList" key={'RoleList' + arbitraryName}>
          {roleInfoList}
        </div>

      </div>
    );
  }
}


export default RoleList;
