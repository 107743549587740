import React from 'react';
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "../../../reducer";

class GeoLocationDisplayMoment extends React.Component {
  constructor(props) {
    super(props);
    this.locationHTML = <div></div>;
    this.lastPositionUpdate = new Date().getTime() / 1000;
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.locationHTML = 'Latitude:  ' + position.coords.latitude +
        'Longitude: ' + position.coords.longitude;
      });
    }
  }

  render() {
    const currentTime = new Date().getTime() / 1000;

    if (currentTime - this.lastPositionUpdate > 3) {
      this.getLocation();
      this.lastPositionUpdate = currentTime;
    }

    return <div>
      <div className="fullRow">
        {this.locationHTML}
      </div>
    </div>;
  }
}

let GeoLocationDisplayMomentConnected = connect(mapStateToProps, mapDispatchToProps)(GeoLocationDisplayMoment);
export default GeoLocationDisplayMomentConnected;
