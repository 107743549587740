import ReactHtmlParser from 'react-html-parser';
import React from 'react';
import $ from "jquery"
import { connect } from "react-redux"
import marked from "markedjs"
import {mapDispatchToProps, mapStateToProps} from "../../../reducer";
import {getBlankScreen} from '../helper_classes.js';

class InstructionMoment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showUnderstandButton: props.jsonObj.showUnderstandButton === undefined || props.jsonObj.showUnderstandButton,
      instructionData: null
    }

    $.get(props.jsonObj.filePath, function(data) {
      this.setState({instructionData: data})
    }.bind(this), 'text');
  }

  render() {
    if (this.state.instructionData === null ) {
      return <div>Loading instructions...</div>;
    } else if(this.props.momentComplete) {
      return getBlankScreen()
    } else{
      const asString = marked(this.state.instructionData);
      const onClickFunc = function(p) {
        this.props.setmomentComplete()
      }.bind(this);
      return  <div className="playback-instructions-pading">{ReactHtmlParser(asString)}<br />
                {this.state.showUnderstandButton &&
                <button className="button" type="button" onClick={onClickFunc}>I Understand</button> 
                }
              </div>;
    }
  }
}

let InstructionMomentConnected = connect(mapStateToProps, mapDispatchToProps)(InstructionMoment);
export default InstructionMomentConnected;
