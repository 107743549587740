import React from 'react';
import $ from "jquery"
import jsHue from "../../../third-party/jshue-2.1.1/src/jshue.js"
import { connect } from "react-redux"
import {getBlankScreen} from '../helper_classes.js';
import {mapDispatchToProps, mapStateToProps} from "../../../reducer";

class PhilipsHueConnectMoment extends React.Component {
  constructor(props) {
    super(props);
    this.hue = jsHue();
    this.bridgeIP = null;
    this.authenticatedShowing = false;
    this.authenticated = false;
    this.bridge = null;
    this.bridgeUsername = null;
    this.bridgeUser = null;
    this.lampList = null;
    this.lampListShowing = false;
    this.areLampsSelected = false;
    this.selectedLamps = [];
    /**
     * Format
     * [
     *   {
     *     id: X
     *     type: x
     *   }
     * ]
     *
     */
    this.lampsSelected = null;

    this.hue.discover().then(function(bridges) {
      if (bridges.length === 0) {
        console.log('No bridges found. :(');
      } else {
        this.bridgeIP = bridges[0].internalipaddress;
      }
    }.bind(this)).catch((e) => console.log('Error finding bridges', e));
  }

  authenticateBridge() {
    this.bridge = this.hue.bridge(this.bridgeIP);

    // have to press link button
    this.bridge.createUser('rehersalRoom#test').then((data) => {
      // extract bridge-generated username for returned data
      this.bridgeUsername = data[0].success.username;

      // instantiate user object
      this.bridgeUser = this.bridge.user(this.bridgeUsername);
      this.bridgeUser.getLights().then(function(data) {
        this.lampList = data;
      }.bind(this));
      this.authenticated = true;
    });
  }

  render() {
    let relevantMoment = getBlankScreen();

    if (this.bridgeIP === null) {
      relevantMoment = <div><h1>Searching for Hue Hub</h1> - this may take a moment</div>;
    } else if (this.authenticatedShowing && this.lampList === null) {
      const onClickFunc = function(p) {
        this.authenticateBridge();
      }.bind(this);
      relevantMoment = <div><h1>We located a Hub</h1> Once you click the "philips" button on the hub, link below<div></div>
        <button type="button" onClick={onClickFunc}>Link to Hub</button> </div>;
    } else if (this.lampListShowing) {
      const lampHTMLArray = [];
      for (const key of Object.keys(this.lampList)) {
        const name = this.lampList[key].name;
        const type = this.lampList[key].type;
        const html = <div><input type="checkbox" name={name} value={key} />{'Name: ' + name + ' (type: ' + type + ')'}</div>;
        lampHTMLArray.push(html);
      }
      const onClickFunc = function(p) {
        const keys = [];
        $('input:checkbox:checked').each(function() {
          keys.push($(this).val());
        });
        for (const key of keys) {
          this.selectedLamps.push({
            id: key,
            type: this.lampList[key].type,
          });
        }
        this.areLampsSelected = true;
      }.bind(this);

      relevantMoment =
        <div>
          <h1>Select Lights for Performance</h1>
          The lights selected below will be automatically controlled. Be sure they're in your performance area.
          <form>{lampHTMLArray}<br/> <button type="button" onClick={onClickFunc}>Ready!</button> </form>
        </div>;
    }
    return relevantMoment;
  }
};

let PhilipsHueConnectMomentConnected = connect(mapStateToProps, mapDispatchToProps)(PhilipsHueConnectMoment);
export default PhilipsHueConnectMomentConnected;
