import React from 'react';
import {
  Link
} from "react-router-dom";
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "../../../reducer";

class EndPerformanceMoment extends React.Component {
  render() {
    const relevantMoment = <div id="endMomentText">
    <h1>End of play</h1>
    {'We hope you enjoyed this production. Return to the '} <Link to="/immersion" className="end-play-moment-link">immersion room</Link> {'to select another performance?'}
  </div>;
    return relevantMoment;
  }
};

let EndPerformanceMomentConnected = connect(mapStateToProps, mapDispatchToProps)(EndPerformanceMoment);
export default EndPerformanceMomentConnected;
