import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import RoleList from './role_list.js';
import PerformancePlayback from './performance_playback.js';
import PerformanceIDSetup from './performance_id_setup.js';
import PlayCategory from './play_category.js';
import {CSSTransition} from 'react-transition-group';
import {
  Route,
  Switch,
  withRouter,
  Redirect
} from "react-router-dom";
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "../reducer";
import { getBlankScreen } from '../common/playback_classes/helper_classes.js';
import $ from "jquery";
import marked from "markedjs"
import qs from 'query-string';


class ImmersionWelcome extends React.Component {
  componentDidMount(){
    this.props.resetState()
    this.props.socket.emit("resetSocketPerformance")
  }

  render(){
    return  <CSSTransition in={this.props.playInfo === null} unmountOnExit timeout={300} classNames="selectionTransition">
                <div className="allCategoryWrapper">
                  <div className="immersionHomeMessag">
                    <h1>Welcome to the immersion room!</h1>
                    <p>Participating in a show shouldn't require months of rehersal. Below you'll find plays and experiences that put you in the center of the action in just a few minutes. Select a play, follow the instructions, and see what the author has in store! </p>
                  </div>
                  <div key="playBin">
                    {this.props.rows}
                  </div>
                </div>
            </CSSTransition>
  }
};

class ImmersionRoleSelection  extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      descriptionObj: null
    }
    this.explicitPerformanceStart = this.explicitPerformanceStart.bind(this);
  }

  componentDidMount(){
    $.get(this.props.playInfo.playDescriptionLink, function(data) {
      this.setState({descriptionObj: data});
    }.bind(this), 'text');
    this.props.resetRoles()
    this.props.socket.on("otherActorSelectedRole", function(msg){
      this.props.otherActorSelectedRole(msg.roleName)
    }.bind(this));
    this.props.socket.on("otherActorRemovedRole", function(msg){
      this.props.otherActorRemovedRole(msg.roleName)
    }.bind(this));
  }

  componentWillUnmount(){
    this.props.socket.off("otherActorSelectedRole")
    this.props.socket.off("otherActorRemovedRole")
  }

  explicitPerformanceStart(){
    this.props.socket.emit("explicitStart")
  }
  
  render(){
    let asString = ""
    if(this.state.descriptionObj){
      asString = marked(this.state.descriptionObj);
    }

    let allRolesSelected = false
    if(this.props.playInfo !== null){
      allRolesSelected = (this.props.activeRoleNames.length + this.props.otherRolesSelected.length === this.props.playInfo.roles.length)
    }
    return <CSSTransition in={true} unmountOnExit timeout={300} classNames="selectionTransition">
              <div className="playDescriptionScreen">
                <div key="playDescription" className="playDescription">
                  {ReactHtmlParser(asString)}
                </div>
                <div key="getPerformanceID">
                  <PerformanceIDConnected key="performanceIDSetup"/> 
                </div>
                { this.props.performanceID !== null &&
                  <div key="roleSelection" className="roleSelection">
                    <RoleListConnected key="RoleList" playInfo={this.props.playInfo}/>
                  </div>
                }
                { ((allRolesSelected && this.props.isHost) || (this.props.playInfo.allRolesMustBeFilled === false)) &&
                  <button className="button" type="button" onClick={this.explicitPerformanceStart}>Start Show</button> }
              </div>
            </CSSTransition>
  }
};

const ImmersionPlayback = (props) => {
  if(!props.performanceID){
    return <Redirect to={props.location.pathname.replace("/performance", "")} />
  }
  if(props.currentMoment === null){
    return getBlankScreen();
  }
  return  <CSSTransition in={props.currentMoment !== null} unmountOnExit timeout={300} classNames="selectionTransition">
            <div key="performancePlaybackWrapper">
              <PerformancePlaybackConnected key={'PerformancePlayback' + props.momentsReceived} />
            </div>
          </CSSTransition>
};

let ImmersionWelcomeConnected = connect(mapStateToProps, mapDispatchToProps)(withRouter(ImmersionWelcome));
let ImmersionRoleConnected = connect(mapStateToProps, mapDispatchToProps)(withRouter(ImmersionRoleSelection));
let ImmersionPlaybackConnected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ImmersionPlayback));
let RoleListConnected = connect(mapStateToProps, mapDispatchToProps)(RoleList);
let PerformanceIDConnected = connect(mapStateToProps, mapDispatchToProps)(PerformanceIDSetup);
let PerformancePlaybackConnected = connect(mapStateToProps, mapDispatchToProps)(PerformancePlayback);


function getPlayFromList(playList, playName){
  for(let catIdx in playList){
    for(let playIdx in playList[catIdx]){
      let play = playList[catIdx][playIdx]
      if(play.playName === playName){
        return play
      }
    }
  }
  return null
}


class PlayBin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      momentsReceived: 0,
    };
    this.onPlaySelected = this.onPlaySelected.bind(this);
  }

  processInitialPath(playList){
    let pathArray = this.props.location.pathname.split('/')
    // hack based on known indexes
    const playNameIdx = 3
    if(pathArray.length > playNameIdx){
      let playName = pathArray[playNameIdx];
      let playInfo = getPlayFromList(playList, playName)
      if(playInfo){
        this.props.selectPlay(playInfo);
      }
    }
    const roleNameIdx = 5
    if(pathArray.length > roleNameIdx){
      let roleName = pathArray[roleNameIdx];
      this.props.selectRole(roleName);
    }
  }

  // If the play refreshes on a play without a session set, redirect to
  // a page we can render (space selection)
  processImproperPerformanceLink(performanceID){
    let pathArray = this.props.location.pathname.split('/')
    // hack based on known indexes
    const performanceIdx = 6
    if(pathArray.length > performanceIdx &&
      performanceID === null){
      return pathArray.slice(0,performanceIdx).join("/")
    }
    return null
  }

  onPlaySelected(playUUID) {
    if (this.props.immersionPlayList === undefined) {
      return null;
    }
    let playInfo = null

    for (const key in this.props.immersionPlayList) {
      for (const play of this.props.immersionPlayList[key]) {
        if (play.playUUID === playUUID) {
          playInfo = play;
        }
      }
    }
    if(playInfo !== null){
      this.props.selectPlay(playInfo)
    }
  }

  componentDidMount(){
    this.props.socket.emit('requestPlayList');
    this.props.socket.on('playList', function(msg) {
      this.processInitialPath(msg)
      this.props.setImmersionPlayList(msg);
    }.bind(this));

    this.props.socket.on('momentTransition', function(msg) {
      this.momentTransition(msg);
    }.bind(this));

    this.props.socket.on('startPerformance', function(msg) {
      this.props.history.push(this.props.location.pathname + "/performance");
    }.bind(this));
  }

  componentWillUnmount() {
    this.props.socket.off("playList")
    this.props.socket.off('momentTransition')
    this.props.socket.off('startPerformance')
  }

  componentDidUpdate(prevProps){
    if(this.props.immersionPlayList === null){
      return
    }
    if(this.props.playInfo === null){
      return
    }

    let params = qs.parse(this.props.location.search)
    let newParamsSet = {}
    let shouldRefresh = false

    // Pass forward props
    let performanceIDKey = "performanceID"
    let urlPerformanceID = parseInt(params[performanceIDKey])
    if(urlPerformanceID){
      newParamsSet[performanceIDKey] = urlPerformanceID;
    }
    let rolesKey = "roles"
    let roles = params[rolesKey]
    if(roles){
      newParamsSet[rolesKey] = roles
    }
    let isHostKey = "host"
    let isHost = params[isHostKey]
    if(isHost !== undefined){
      newParamsSet[isHostKey] = isHost === 'true'
      isHost = isHost === 'true'
    }

    let momentNumberKey = "moment"
    let momentNumber = params[momentNumberKey]
    if(momentNumber){
      newParamsSet[momentNumberKey] = parseInt(momentNumber)
      momentNumber = parseInt(params[momentNumberKey])
    }
  

    // Roles param
    let urlRoles = []
    if(roles){
      if(Array.isArray(roles)){
        urlRoles = roles
      }else{
        urlRoles = [roles]
      }
    }
    let rolesChanged = prevProps.activeRoleNames !== this.props.activeRoleNames

    let activeRoles = []
    if(this.props.activeRoleNames && this.props.activeRoleNames.length > 0){
      activeRoles = this.props.activeRoleNames
    }
    if(activeRoles.length === 0 && urlRoles.length > 0 && !rolesChanged){
      for(let roleIdx in urlRoles){
        let roleName = urlRoles[roleIdx]
        this.props.selectRole(roleName);
        this.props.socket.emit("roleSelected", {"roleName": roleName});
      }
      activeRoles = urlRoles
    }

    if(urlRoles.length !== activeRoles.length){
        shouldRefresh = true
        newParamsSet[rolesKey] = this.props.activeRoleNames
    }

    // Is Host Params
    if(this.props.isHost === null && isHost !== undefined){
      this.props.setIsHost(isHost)
    }else if(this.props.isHost !== null && 
              this.props.isHost !== isHost){
      newParamsSet[isHostKey] = this.props.isHost
      shouldRefresh = true
    }

    // Performance ID Param
    if((urlPerformanceID || this.props.performanceID) &&
        urlPerformanceID !== this.props.performanceID){
      shouldRefresh = true
      if(this.props.performanceID){
        newParamsSet[performanceIDKey] = this.props.performanceID
      }else if(urlPerformanceID){
        let playUUID = null
        if(this.props.playInfo){
          playUUID = this.props.playInfo.playUUID
        }
        this.props.selectPerformance(newParamsSet[performanceIDKey]);
        if(isHost === undefined){
          isHost = false
        }
        this.props.socket.emit('connectToPerformanceID', {
          performanceID: urlPerformanceID,
          activeRoleNames: this.props.activeRoleNames,
          playUUID: playUUID,
          onlyConnectAsHost: isHost
        });
      }
    }

    // Moment Number param
    if(this.props.playInfo && this.props.momentNumber === null && momentNumber){
      let script = this.props.playInfo["script"]
      this.props.setcurrentMoment(script[momentNumber], momentNumber)
      newParamsSet[momentNumberKey] = parseInt(momentNumber)
      shouldRefresh = true
    }else  if(this.props.momentNumber !== momentNumber){
      newParamsSet[momentNumberKey] = this.props.momentNumber
      shouldRefresh = true
    }

    if(shouldRefresh){
      // Set in URL paramater
      let searchString = qs.stringify(newParamsSet)
      this.props.history.push({search: searchString});
    }
  }

  momentTransition(msg) {
    this.props.setcurrentMoment(msg.momentContents, msg.momentNumber);
    this.setState({momentsReceived: this.state.momentsReceived + 1});
  }

  render() {
    const rows = [];
    let i = 0;
    for (const key in this.props.immersionPlayList) {
      i++;
      rows.push(<PlayCategory key={i} onPlaySelected={this.onPlaySelected} category={key} playList={this.props.immersionPlayList[key]} />);
    }
    if(rows.length === 0 || this.props.immersionPlayList === null){
      return <Route exact path="/immersion" render={() => <ImmersionWelcomeConnected rows={rows}/>} /> 
    }

    let improperPerformanceRedirect = this.processImproperPerformanceLink(this.props.performanceID)
    if(improperPerformanceRedirect){
      return <Redirect to={improperPerformanceRedirect}/>
    }

    return (
      <div>
          <Switch>
            <Route exact path="/immersion" render={() => <ImmersionWelcomeConnected rows={rows}/>} />          
            <Route exact path='/immersion/play/:playName' component={ImmersionRoleConnected} />              
            { this.props.performanceID &&
              <Route path='/immersion/play/:playName/performance' render={() => <ImmersionPlaybackConnected momentsReceived={this.state.momentsReceived} />} />
            }
            <Route path="*" ><Redirect to="/immersion" /></Route>
          </Switch>
      </div>
    );
  }
}

let PlayBinConnected = connect(mapStateToProps, mapDispatchToProps)(PlayBin);


export default withRouter(PlayBinConnected);
