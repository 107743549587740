import React from 'react';
import '../third-party/foundation.css';
import './landing_page.css'
import {
  Link,
} from "react-router-dom";
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "../reducer";


class LandingPage extends React.Component {

  componentDidMount(){
    this.props.socket.emit('requestPlayList');
    this.props.socket.on('playList', function(msg) {
      if(this.props.immersionPlayList === null){
        this.props.setImmersionPlayList(msg);
      }
    }.bind(this));
  }

  componentWillUnmount(){
    this.props.socket.off("playList")
  }


  render() {
    return (
      <div className="landing-page-base">
        <div className='splash-section'>
          <div className="landing-splash-buffer" />
          <div className="splash-content" >
              <p className="tag-line">Build your repertoire</p>
              <div className="big-seperator" />
              <img className="logo-image" src="/images/brand_assets/logo_no_border.svg" alt="Company Logo"/>
              <h1>
                <span className="big-tag-line">Theater is more than three walls and an audience.</span><br/>
              </h1>
              <div className="skinny-seperator" />
              <div className="splash-summary">
                inRep's tools give you the ability to create and experience theaterical events in your own home.
              </div>
          </div>
          <div className="landing-splash-buffer" />
        </div>
        <div className="break" />


        <div className="immersion-promo-section">
          <div className="immersion-splash-buffer" />
          <div className="immersion-content">
            <Link to="/immersion"><p className="immersion-tag-line">The Immersion Room</p></Link>
              <h2 className="immersion-description">
                You're the lead<br/> 
                no rehersal required.
              </h2>
              <Link to="/immersion"><img className="immersion-image" src="/images/static_immersive.svg" alt="Link to immersion room"/></Link>
              <h3 className="immersion-subtitle">Grab some friends and a couple square feet of carpet.<br/> Then let the show begin.</h3>
          </div>
          <div className="immersion-splash-buffer" />
        </div>
      </div>

    );
  }
}

let LandingPageConnected = connect(mapStateToProps, mapDispatchToProps)(LandingPage);
export default LandingPageConnected;
