import {MomentFactory} from '../common/playback_classes/moment_factory.js';
import React from 'react';
import {
  withRouter,
  Link,
//  Prompt
} from "react-router-dom";
import {getmomentTypeForRole} from "../common/playback_classes/helper_classes.js";

class PerformancePlayback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moment: new MomentFactory(this.props.currentMoment),
      showDisconnectMessage: false,
      completeMessageSent: false
    };
    this.goToLocation = this.goToLocation.bind(this)
  }

  componentDidMount(){
    this.props.socket.on("momentFinished", function(){
      // TODO: Extract the proper moment
      let momentType = getmomentTypeForRole(this.props.currentMoment, this.props.activeRoleNames[0]);
      if(momentType !== "endPerformance"){
        this.setState({showDisconnectMessage: true})
      }

    }.bind(this));
    this.updateFunc = this.updateFunc.bind(this);
    this.intervalFunc = setInterval(function() {
      this.updateFunc();
    }.bind(this), 500);
  }

  componentWillUnmount() {
    this.props.socket.off("momentFinished")
    clearInterval(this.intervalFunc);
  }

  updateFunc() {
    if (this.props.momentComplete && !this.state.completeMessageSent) {
      this.props.socket.emit('momentComplete', {momentName: this.state.moment.momentName});
      this.setState({completeMessageSent: true});
    }
  }

  goToLocation(entry) {
    this.props.socket.emit("goToMoment", {"momentNumber": entry.location})
  }

  render() {
    if(this.state.showDisconnectMessage){
      return <div className="disconnectScreen">A performer disconnected - please return to the <Link to="/immersion" className="end-play-moment-link">immersion room</Link> to start a new performance.</div>
    }
    //<Prompt message={location => 'Are you sure you want to end the performance for everyone?'} />
    return (
      <div className="playbackUI" id={'ImmersionUI' + this.forceUpdateCount } key={'ImmersionUI' + this.forceUpdateCount }>
        {this.state.moment}
        {this.props.playInfo.scriptNav &&
          <div className="break" /> &&
          <div className="readingNavigation" >
            {this.props.playInfo.scriptNav.map(entry => {
              if(entry.type === "act"){
                return <div className="navEntry navAct" onClick={() =>{this.goToLocation(entry)}} >{"Act " + entry.name}</div>
              }else if(entry.type === "scene"){
                return <div className="navEntry navmoment" onClick={() =>{this.goToLocation(entry)}}>{entry.name}</div>
              }
              return <div></div>
            })}
          </div>
        }
      </div>
    );
  }
}


export default withRouter(PerformancePlayback);
