import React from 'react';
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "../../../reducer";

class TimeDelayMoment extends React.Component {
  constructor(props) {
    super(props);
    this.momentLength = props.jsonObj.momentLength;
    this.startTime = null;
  }

  setmomentComplete() {
    this.momentComplete = true;
  }
  render() {
    return <div>Keep Going...</div>;
  }

}


let TimeDelayMomentConnected = connect(mapStateToProps, mapDispatchToProps)(TimeDelayMoment);
export default TimeDelayMomentConnected;
