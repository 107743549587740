import React from 'react';
import ReactDOM from 'react-dom';
import {createStore} from "redux";
import { Provider } from "react-redux";
import {immersionPlay} from "./reducer";
import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.css';
import './immersion_room/immersion.css'

let store = createStore(immersionPlay);

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
