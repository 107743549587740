import React from 'react';
import { connect } from "react-redux"
import {mapDispatchToProps, mapStateToProps} from "../../reducer";
import {getBlankScreen} from './helper_classes.js';

import ActorReadingConnected from './moments/actor_reading.js';
import AlexaMomentConnected from './moments/alexa_moment.js';
import AudioControlMomentConnected from './moments/audio_control_moment.js';
import DialogueMomentConnected from './moments/dialogue_moment.js';
import EndPerformanceMomentConnected from './moments/end_performance_moment.js';
import GeoLocationDisplayMomentConnected from './moments/geo_location_display_moment.js';
import GeoLocationTriggerMomentConnected from './moments/geo_location_trigger_moment.js';
import InstructionMomentConnected from './moments/instruction_moment.js';
import PhilipsHueConnectMomentConnected from './moments/philips_hue_connect_moment.js';
import PhilipsHuePlaybackMomentConnected from './moments/philips_hue_playback_moment.js';
import TimeDelayMomentConnected from './moments/time_delay_moment.js';

class MomentFactory {
  constructor(jsonObj) {
    if (jsonObj.momentType === 'actorReading') {
      return  <ActorReadingConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'alexa') {
      return  <AlexaMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'audio_control') {
      return  <AudioControlMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'branching') {
      return <BranchingMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'dialogue') {
      return  <DialogueMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'endPerformance') {
      return  <EndPerformanceMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'geoLocationDisplay') {
      return  <GeoLocationDisplayMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'geoLocationTrigger') {
      return <GeoLocationTriggerMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'instructions') {
      return  <InstructionMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'philipsHueConnect') {
      return  <PhilipsHueConnectMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'philipsHuePlayback') {
      return <PhilipsHuePlaybackMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'random') {
      return <RandomMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'repeat') {
      return <RepeatMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'sequential') {
      return  <SequentialMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'simultaneous') {
      return <SimultaneousMomentConnected jsonObj={jsonObj} />;
    } else if (jsonObj.momentType === 'timeDelay') {
      return  <TimeDelayMomentConnected jsonObj={jsonObj} />;
    }
  }
}

class SequentialMoment extends React.Component {
  constructor(props) {
    super(props);
    this.moments = [];
    this.currentMomentIdx = 0;
    for (const moment of props.jsonObj.moments) {
      this.moments.push(new MomentFactory(moment));
    }
  }

  render() {
    const currentMoment = this.moments[this.currentMomentIdx];
    return currentMoment;
  }
};


class BranchButton extends React.Component {
  constructor(props) {
    super(props);
    this.onSelected = this.onSelected.bind(this);
  }

  onSelected(e) {
    this.props.onBranchSelected(this.props.branchName);
  }
  componentWillUnmount() {
  }

  render() {
    return (
      <div className="roleDescription" key={'branchDescription' + this.props.branchName} onClick={(e) => this.onSelected(e)}>
        <p>{this.props.branchName}</p>
      </div>
    );
  }
}

class BranchingMoment extends React.Component {
  constructor(props) {
    super(props);
    this.moments = [];
    for (const moment of props.jsonObj.moments) {
      this.moments.push(new MomentFactory(moment));
    }
    this.selectedMoment = null;
    this.onBranchSelected = this.onBranchSelected.bind(this);
  }

  onBranchSelected(branchName) {
    for (const moment of this.moments) {
      if (branchName === moment.momentName) {
        this.selectedMoment = moment;
        break;
      }
    }
  }

  render() {
    if (this.selectedMoment != null) {
      return this.selectedMoment;
    }
    const options = [];
    for (const idx in this.moments) {
      const moment = this.moments[idx];
      options.push(<BranchButton key={moment.momentName} onBranchSelected={this.onBranchSelected} branchName={moment.momentName} />);
    }
    return <div>{options}</div>;
  }
};


class RepeatMoment extends React.Component {
  constructor(props) {
    super(props);
    this.momentJson = props.jsonObj.moment;
    if (this.secretJson === null) {
      this.secretJson = {};
    }
    this.repeatCount = props.jsonObj.repeatCount;
    this.moment = null;
    this.momentIdx = 0;
    this.momentComplete = false;
  }

  render() {
    if (this.momentComplete) {
      return getBlankScreen();
    }
    if (this.moment === null) {
      this.moment = new MomentFactory(this.momentJson, this.secretJson);
    }

    /**if (this.moment.(roleInfo)) {
      if (this.repeatCount === 0) {
        // Repeat Infinitely
        this.momentList.push(new MomentFactory(this.momentJson, this.secretJson));
        this.moment.reset();
        this.moment.playDialogue();
      } else {
        this.repeatCount -= 1;
        if (this.repeatCount === 0) {
          this.momentComplete = true;
          return getBlankScreen();
        } else {
          this.moment.reset();
          this.moment.playDialogue();
        }
      }
    }**/

    return this.moment;
  }
};

class RandomMoment extends React.Component {
  constructor(props) {
    super(props);
    this.moments = [];
    for (const moment of props.jsonObj.moments) {
      this.moments.push(new MomentFactory(moment, ));
    }
    this.selectedMoment = this.moments[this.getRandomIdx()];
  }

  getRandomIdx() {
    return Math.floor(Math.random() * Math.floor(this.moments.length));
  }

  render() {
    return this.selectedMoment;
  }
};

class SimultaneousMoment extends React.Component {
  constructor(props) {
    super(props);
    this.moments = [];
    this.transitionToWaitOnComplete = this.props.jsonObj.transitionToWaitOnComplete
    if(this.transitionToWaitOnComplete === undefined){
      this.transitionToWaitOnComplete = true
    }
    for (const moment of this.props.jsonObj.moments) {
      if (props.activeRoleNames.includes(moment.roleName)) {
        this.moments.push(new MomentFactory(moment));
      }
    }
  }

  render() {
    let relevantMoment = getBlankScreen();

    for (let moment of this.moments) {
      if (this.props.activeRoleNames.includes(moment.props.jsonObj.roleName)) {
        relevantMoment = moment;
        break;
      }
    }

    return relevantMoment;
  }
};


let SequentialMomentConnected = connect(mapStateToProps, mapDispatchToProps)(SequentialMoment);
let BranchingMomentConnected = connect(mapStateToProps, mapDispatchToProps)(BranchingMoment);
let RepeatMomentConnected = connect(mapStateToProps, mapDispatchToProps)(RepeatMoment);
let RandomMomentConnected = connect(mapStateToProps, mapDispatchToProps)(RandomMoment);
let SimultaneousMomentConnected = connect(mapStateToProps, mapDispatchToProps)(SimultaneousMoment);


export { MomentFactory, SequentialMomentConnected, BranchingMomentConnected, RepeatMomentConnected, RandomMomentConnected, SimultaneousMomentConnected};
