import React from 'react';

function getBlankScreen() {
  return <div id="blankScreen" className="blank-screen-waiting" key="blankScreen"><h1>Wait for other performers</h1></div>;
}

function getRoleInPlay(play, roleName){
  if(play === null){
    return null;
  }
  for(let idx in play.roles){
    let role = play.roles[idx]
    if(role.roleName === roleName){
      return role;
    }
  }
  return null;
}

function getmomentTypeForRole(currentMoment, roleName){
  if(currentMoment.roleName === roleName){
    return currentMoment.momentType;
  }else if(currentMoment.momentType === "simultaneous"){
    for(let idx in currentMoment.moments){
      let moment = currentMoment.moments[idx]
      if(moment.roleName === roleName){
        return moment.momentType;
      }
    }
  }
  return null;
}

export {getBlankScreen, getRoleInPlay, getmomentTypeForRole};
