function immersionPlay(state, action) {
    if (action.type === "reset_state"){
        state = { socket: state.socket,
                  immersionPlayList: state.immersionPlayList,
                  playInfo: null,
                  activeRoleNames: [],
                  performanceID: null,
                  shouldStartPerformance: null,
                  currentMoment: null,
                  momentNumber: null,
                  momentComplete: false,
                  isHost: null,
                  otherRolesSelected: []}
    }else if (state === undefined ){
        state = { socket: null,
                  immersionPlayList: null,
                  playInfo: null,
                  activeRoleNames: [],
                  performanceID: null,
                  shouldStartPerformance: null,
                  currentMoment: null,
                  momentNumber: null,
                  momentComplete: false,
                  isHost: null,
                  otherRolesSelected: []}
    }

    let newState = Object.assign({}, state);

    switch(action.type) {
        case "set_socket": {
            newState["socket"] = action.socket;
            break;
        }
        case "got_play_list": {
          newState["immersionPlayList"] = action.playList;
          break;
        }
        case "play_selected": {
            newState["playInfo"] = action.playInfo;
            break;
        }
        case "role_selected": {
            let roleNames = [...newState["activeRoleNames"]]
            roleNames.push(action.roleName)
            newState["activeRoleNames"] = roleNames;
            break;
        }
        case "remove_role": {
            let roleNames = newState["activeRoleNames"].filter(function(value, index, arr){
              return value !== action.roleName;
            })
            newState["activeRoleNames"] = roleNames
            break;
        }
        case "set_is_host": {
          newState["isHost"] = action.isHost;
          break;
        }
        case "reset_roles": {
          newState["activeRoleNames"] = [];
          newState["performanceID"] = null;
          break;
        }
        case "other_actor_selected_role": {
          if(!newState["activeRoleNames"].includes(action.roleName)){
            let roleNames = newState["otherRolesSelected"] = [...newState["otherRolesSelected"]]
            roleNames.push(action.roleName)
            newState["otherRolesSelected"] = roleNames
          }
          break;
        }
        case "other_actor_removed_role": {
          let roleNames = newState["otherRolesSelected"].filter(function(value, index, arr){
            return value !== action.roleName;
          })
          newState["otherRolesSelected"] = roleNames
          break;
        }
        case "performance_selected": {
            newState["performanceID"] = action.performanceID;
            break;
        }
        case "start_performance": {
            newState["shouldStartPerformance"] = true
            break;
        }
        case "current_moment": {
            newState["currentMoment"] = action.moment;
            newState["momentNumber"] = action.momentNumber
            newState["momentComplete"] = false;
            break;
        }
        case "moment_complete": {
          newState["momentComplete"] = true;
          break;
        }
        default: {
        }
    }
    return newState;
}

// Map Redux state to component props
function mapStateToProps(state) {
    return {
      playInfo: state.playInfo,
      immersionPlayList: state.immersionPlayList,
      activeRoleNames: state.activeRoleNames,
      performanceID: state.performanceID,
      shouldStartPerformance: state.shouldStartPerformance,
      socket: state.socket,
      currentMoment: state.currentMoment,
      momentNumber: state.momentNumber,
      momentComplete: state.momentComplete,
      isHost: state.isHost,
      otherRolesSelected: state.otherRolesSelected
    };
  }
  
  // Map Redux actions to component props
  function mapDispatchToProps(dispatch) {
    return {
      resetState: function() {
        const resetPerformance = {
          type: "reset_state",
        };
        return dispatch(resetPerformance);
      },
      setSocket: function(socket){
        const setSocket = {
          type: "set_socket",
          socket: socket
        }
        return dispatch(setSocket)
      },
      setImmersionPlayList: function(playList){
        const immersionPlayAction = {
          type: "got_play_list",
          playList: playList
        }
        return dispatch(immersionPlayAction)
      },
      selectPlay: function(playInfo) {
        const selectAction = {
          type: "play_selected",
          playInfo: playInfo,
        };
        return dispatch(selectAction);
      },
      selectRole: function(roleName) {
        const selectRole = {
          type: "role_selected",
          roleName: roleName
        };
        return dispatch(selectRole);
      },
      removeRole: function(roleName) {
        const removeRole = {
          type: "remove_role",
          roleName: roleName
        }
        return dispatch(removeRole);
      },
      otherActorSelectedRole: function(roleName) {
        const removeRole = {
          type: "other_actor_selected_role",
          roleName: roleName
        }
        return dispatch(removeRole);
      },
      otherActorRemovedRole: function(roleName){
        const removeRole = {
          type: "other_actor_removed_role",
          roleName: roleName
        }
        return dispatch(removeRole);
      },
      resetRoles: function(){
        const resetRoles = {
          type: "reset_roles"
        }
        return dispatch(resetRoles);
      },
      selectPerformance: function(performanceID) {
        const selectPerformance = {
          type: "performance_selected",
          performanceID: performanceID
        };
        return dispatch(selectPerformance);
      },
      startPerformance: function() {
        const startPerformance = {
          type: "start_performance",
        };
        return dispatch(startPerformance);
      },
      setcurrentMoment: function(moment, momentNumber) {
          const currentMoment = {
              type: "current_moment",
              moment: moment,
              momentNumber: momentNumber
          }
          return dispatch(currentMoment)
      },
      setmomentComplete: function() {
        const momentComplete = {
          type: "moment_complete"
        }
        return dispatch(momentComplete);
      },
      setIsHost: function(isHost) {
        const isHostAction = {
          type: "set_is_host",
          isHost: isHost
        }
        return dispatch(isHostAction)
      }
    };
  }

export {immersionPlay, mapDispatchToProps, mapStateToProps};